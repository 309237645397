import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltips from '../../components/bootstrap/Tooltips';
import ThemeContext from '../../contexts/themeContext';
import useAsideTouch from '../../hooks/useAsideTouch';
import { dashboardMenu } from '../../menu';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import {
	accountPage,
	activityLog,
	allVehicle,
	auctions,
	bidAuction,
	buyers,
	gatePass,
	misc,
	paddle,
	settings,
} from './menus';

interface AuthState {
	auth: any;
}

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { permissions } = useSelector((state: AuthState) => state.auth);
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);
	const [doc] = useState(false);

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside left-sidebar',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head' style={{ background: '#9f1b20' }}>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body' style={{ background: '#B11E24' }}>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					<NavigationLine />
					{!doc && (
						<>
							{permissions?.['member.index'] && (
								<>
									<Navigation menu={buyers(permissions)} id='customer-pages' />
									<NavigationLine />
								</>
							)}

							{permissions?.['vehicle.index'] && (
								<>
									<Navigation
										menu={allVehicle(permissions)}
										id='aside-demo-pages'
									/>
									<NavigationLine />
								</>
							)}

							{permissions?.['auction.index'] && (
								<Navigation
									menu={auctions(permissions)}
									id='aside-auctions-pages'
								/>
							)}

							{permissions?.['bid_history.index'] && (
								<>
									<Navigation
										menu={bidAuction(permissions)}
										id='aside-bidAuction-pages'
									/>
									<NavigationLine />
								</>
							)}

							{(permissions?.['banners.index'] ||
								permissions?.['catalogues.index'] ||
								permissions?.['faq.index'] ||
								permissions?.['jobs.index'] ||
								permissions?.['downloads.index'] ||
								permissions?.['content.index'] ||
								permissions?.['blog.index']) && (
								<>
									<Navigation menu={misc(permissions)} id='aside-demo-misc' />
									<NavigationLine />
								</>
							)}

							{(permissions?.['accounting.index'] ||
								permissions?.['product-services.index']) && (
								<>
									<Navigation
										menu={accountPage(permissions)}
										id='aside-accounting-pages'
									/>
									<NavigationLine />
								</>
							)}

							{permissions?.['paddle.index'] && (
								<Navigation menu={paddle(permissions)} id='paddle' />
							)}

							{permissions?.['gatekeeper.index'] && (
								<>
									<Navigation
										menu={gatePass(permissions)}
										id='aside-activity-log-pages'
									/>
								</>
							)}

							{(permissions?.['page.index'] ||
								permissions?.['email-notification.index'] ||
								permissions?.['faq.index'] ||
								permissions?.['model.index'] ||
								permissions?.['vehicle-damages.index'] ||
								permissions?.['vehicle-colors.index'] ||
								permissions?.['body-styles.index'] ||
								permissions?.['make.index'] ||
								permissions?.['yard.index'] ||
								permissions?.['location.index'] ||
								permissions?.['state.index'] ||
								permissions?.['country.index'] ||
								permissions?.['role.index'] ||
								permissions?.['user.index']) && (
								<Navigation menu={settings(permissions)} id='settings-page' />
							)}

							{permissions?.['activity_logs.index'] && (
								<>
									<NavigationLine />
									<Navigation
										menu={activityLog(permissions)}
										id='aside-activity-log-pages'
									/>
									<NavigationLine />
								</>
							)}
						</>
					)}
				</div>
				<div className='aside-foot' style={{ background: '#B11E24' }}>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
