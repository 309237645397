import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
// import { current } from 'immer';
import { getTimezoneAbbreviation } from '../../App/time';
import { authAxios } from '../../axious-config';
import { calculateBidDecrement, calculateBidIncrement } from '../../components/Auction/increment';
import { userLoggedOut } from '../auth/authSlice';

export const getAuctionList = createAsyncThunk(
	'auction/list',
	async ({ timezone, tz_short, running_auction_ids }, thunkAPI) => {
		try {
			const response = await authAxios.post(`/auction-dashboard`, {
				timezone,
				tz_short,
				except_ids: running_auction_ids,
			});

			return response.data;
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

export const joinAuction = createAsyncThunk(
	'auction/join',
	async ({ auction_id, token }, thunkAPI) => {
		try {
			const response = await authAxios.get(`/auctions/${auction_id}/join`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			return response.data;
		} catch (e) {
			if (e.response?.status === 401) {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				const tz_short = getTimezoneAbbreviation(new Date());
				const running_auction_ids = [];
				thunkAPI.dispatch(userLoggedOut());
				thunkAPI.dispatch(getAuctionList({ timezone, tz_short, running_auction_ids }));
			}
			return thunkAPI.rejectWithValue({ error: e.response.data, auction_id });
		}
	},
);

export const setWatchUnwatch = createAsyncThunk(
	'auction/watch',
	async ({ auctionId, vehicleId, watch, lotIndex }, thunkAPI) => {
		const { access_token } = JSON.parse(localStorage.getItem('authData'));

		try {
			const response = await authAxios.post(
				`/vehicles/${vehicleId}/toggle-watch`,
				{ watched: !watch },
				{
					headers: {
						Authorization: 'Bearer ' + access_token,
					},
				},
			);

			let res = await response.data;
			return { res, auctionId, vehicleId, watch, lotIndex };
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);
export const setOfflineMax = createAsyncThunk(
	'auction/maxBid',
	async ({ auctionId, vehicleId, lotIndex, amount }) => {
		const { access_token } = JSON.parse(localStorage.getItem('authData'));

		try {
			const response = await authAxios.post(
				`/lot/${vehicleId}/offline-bid`,
				{ type: 'max', amount },
				{
					headers: {
						Authorization: 'Bearer ' + access_token,
					},
				},
			);

			let res = await response.data;
			return { res, auctionId, vehicleId, lotIndex, message: 'success' };
		} catch (e) {
			return { auctionId, vehicleId, lotIndex, message: 'error' };
			// return thunkAPI.rejectWithValue(e.response.data);
		}
	},
);

const initialState = {
	add_auction: false,
	auction_list_loading: false,
	join_auction_loading: false,
	auction_list: '',
	all_auction: [],
	running_auction: [],
	running_auction_ids: [],
};

export const auctionSlice = createSlice({
	name: 'auction',
	initialState,
	reducers: {
		setAddAuction: (state, action) => {
			state.add_auction = action.payload;
		},
		setAllAuction(state, action) {
			state.all_auction = [];
			state.all_auction.push(action.payload);
		},
		setRunningAuction(state, action) {
			state.running_auction = [];
			state.running_auction.push(action);
			state.join_auction_loading = false;
		},
		readyToBid(state, action) {
			const {
				auctionId,
				event,
				total_participants,
				bid_info,
				bid_detail,
				interval,
				msg,
				total_remaining_items,
				show_start_button,
				show_finish_button,
			} = action.payload;

			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			console.log(current(state_obj));
			let own_bid_amount = null;
			let newStateData = {
				...state_obj,
				event,
				total_participants,
				bid_info,
				own_bid_amount,
				interval,
				msg,
				total_remaining_items,
			};
			let { vehicle_detail, upcoming_vehicles, skipped_vehicles } = state_obj;

			if (
				state_obj?.vehicle_detail &&
				state_obj.vehicle_detail?.item_number_str !== bid_info.current_item
			) {
				let item_number = bid_info.item_number;
				let upcomingVehicles = state_obj.upcoming_vehicles;
				let indexOfArray = upcomingVehicles.findIndex((e) => e.item_number == item_number);

				if (indexOfArray != -1) {
					vehicle_detail =
						state.all_auction[index].upcoming_vehicles[bid_info.current_item];
					upcoming_vehicles = upcomingVehicles.filter((_, i) => i !== indexOfArray);
				} else {
					vehicle_detail =
						state.all_auction[index].skipped_vehicles[bid_info.current_item];
					let skippedVehicles = state_obj.skipped_vehicles;
					let ind = skippedVehicles.findIndex((e) => e.item_number == item_number);
					skipped_vehicles = skippedVehicles.filter((_, i) => i !== ind);
				}

				vehicle_detail = state.all_auction[index].upcoming_vehicles[bid_info.current_item];
			}

			vehicle_detail.show_finish_button = show_finish_button;
			vehicle_detail.show_start_button = show_start_button;

			state.running_auction[index] = {
				...newStateData,
				vehicle_detail,
				upcoming_vehicles,
				skipped_vehicles,
				bid_detail,
			};
		},
		newBid(state, action) {
			const {
				auctionId,
				userId,
				event,
				total_participants,
				bid_info,
				bid_detail,
				interval,
				msg,
			} = action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			console.log(current(state));

			state.running_auction[index] = {
				...state_obj,
				event,
				total_participants,
				bid_info,
				bid_detail,
				interval,
				msg,
			};

			if (userId === bid_detail?.user_id) {
				let obj = { ...action.payload, index, bidAmount: bid_detail?.amount };
				auctionSlice.caseReducers.setOwnBidAmount(state, obj);
			}
		},
		bonusTime(state, action) {
			const { auctionId, event, total_participants, bid_detail, interval, msg } =
				action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			state.running_auction[index] = {
				...state_obj,
				event,
				total_participants,
				bid_detail,
				interval,
				msg,
			};
		},
		auctionBreak(state, action) {
			const { auctionId, ...rest } = action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];

			let is_break_running = false;
			if (rest.type == 'started') {
				is_break_running = true;
			}
			state.running_auction[index] = {
				...state_obj,
				break: rest,
				is_break_running,
			};
		},
		bidEnded(state, action) {
			const {
				auctionId,
				event,
				item_number,
				auction_finished,
				interval,
				msg,
				next_item_number,
				show_finish_button,
				show_start_button,
			} = action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];

			const { upcoming_vehicles, skipped_vehicles } = state_obj;

			if (
				!auction_finished &&
				(upcoming_vehicles.length > 0 || skipped_vehicles.length > 0)
			) {
				let upcoming_index = upcoming_vehicles.findIndex(
					(i) => i.item_number == next_item_number,
				);

				let new_vehicle_detail = null;

				if (upcoming_index != -1) {
					new_vehicle_detail = upcoming_vehicles[upcoming_index];
					let new_upcoming_list = upcoming_vehicles.filter(
						(_, i) => i !== upcoming_index,
					);

					state_obj.upcoming_vehicles = new_upcoming_list;
				} else {
					let upcoming_ind = skipped_vehicles.findIndex(
						(i) => i.item_number == next_item_number,
					);

					new_vehicle_detail = skipped_vehicles[upcoming_ind];
					let new_skipped_list = skipped_vehicles.filter((_, i) => i !== upcoming_ind);
					state_obj.skipped_vehicles = new_skipped_list;
				}

				if (new_vehicle_detail) {
					new_vehicle_detail.show_finish_button = show_finish_button;
					new_vehicle_detail.show_start_button = show_start_button;
					state_obj.vehicle_detail = new_vehicle_detail;
				}
			}

			state.running_auction[index] = {
				...state_obj,
				event,
				item_number,
				auction_finished,
				interval,
				msg,
			};
		},

		skippedVehicle(state, action) {
			const { auctionId, upcoming_vehicles, skipped_vehicles } = action.payload;

			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			let newStateData = {
				...state_obj,
			};

			state.running_auction[index] = {
				...newStateData,
				upcoming_vehicles,
				skipped_vehicles,
			};
		},

		setRunningAuctionIds(state, action) {
			state.running_auction_ids = action.payload.running_auction_ids;
		},

		setAuctionList(state, action) {
			state.auction_list = action.payload;
			state.auction_list_loading = false;
		},

		setOwnBidAmount(state, action) {
			const { index, bidAmount } = action;
			state.running_auction[index].own_bid_amount = bidAmount;
		},

		removeAllAuction(state, action) {
			state.all_auction.splice(action.payload.index, 1);
		},

		removeRunningAuction(state, action) {
			const prevState = [...state.running_auction];
			prevState.splice(action.payload.index, 1);
			state.running_auction = prevState;
			state.running_auction_ids = state.running_auction_ids.filter(
				(e) => e !== action.payload.id,
			);
			auctionSlice.caseReducers.removeAllAuction(state, action);
		},

		setWatch(state, action) {
			const { auctionId, vehicleId, lotIndex, watch } = action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			if (state_obj?.upcoming_vehicles[lotIndex]?.id == vehicleId) {
				state_obj.upcoming_vehicles[lotIndex].watched = !watch;
			}
			state.running_auction[index] = state_obj;
		},

		setCurrentBidAmount(state, action) {
			const { auctionId, vehicleId, lotIndex, res, message } = action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			if (state_obj?.upcoming_vehicles[lotIndex]?.id == vehicleId) {
				state_obj.upcoming_vehicles[lotIndex].current_bid_amount = res.current_bid;
				state_obj.offiline_max_bid_success = message;
			}
			state.running_auction[index] = state_obj;
		},

		setMaxValue(state, action) {
			const { auctionId, vehicleId, lotIndex, bid_increment, max_minimum_bid, actionType } =
				action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			if (state_obj?.upcoming_vehicles[lotIndex]?.id == vehicleId) {
				let previousValue = state_obj.upcoming_vehicles[lotIndex].changingMaxValue;

				let increment = calculateBidIncrement(previousValue);
				let decrement = calculateBidDecrement(previousValue);

				let newValue;
				if (actionType === 'plus') {
					newValue = previousValue
						? previousValue + increment
						: max_minimum_bid + bid_increment;
				} else if (actionType === 'minus') {
					newValue = previousValue
						? previousValue - decrement
						: max_minimum_bid - bid_increment;
				}
				state_obj.upcoming_vehicles[lotIndex].changingMaxValue = newValue;
			}
			state.running_auction[index] = state_obj;
		},

		setUpcomingVehicle(state, action) {
			const { auctionId, upcomingVehicles } = action.payload;
			let upcoming_vehicles = Object.values(upcomingVehicles);
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			state_obj.upcoming_vehicles = upcoming_vehicles;
			state.running_auction[index] = state_obj;
		},

		setAuctionEnded(state, action) {
			let index = action.payload.index;
			let state_obj = state.running_auction[index];
			let auction_ended = true;
			state.running_auction[index] = { ...state_obj, auction_ended };
		},

		setAuctionSound(state, action) {
			let { index, sound } = action.payload;
			let state_obj = state.running_auction[index];
			let auctionSound = sound;
			state.running_auction[index] = { ...state_obj, auctionSound };
		},

		clearAuction(state) {
			state.auction_list_loading = false;
			state.auction_list = '';
			state.all_auction = [];
			state.running_auction = [];
			state.running_auction_ids = [];
		},
		offlineMaxBidStatusChange(state, action) {
			let { auctionId, status } = action.payload;
			let index = state.running_auction.findIndex((i) => i.auction_detail.id == auctionId);
			let state_obj = state.running_auction[index];
			state_obj.offiline_max_bid_success = status;
		},
		finishAuction(state, action) {
			console.log(state, action);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAuctionList.pending, (state) => {
				state.auction_list_loading = true;
			})
			.addCase(getAuctionList.fulfilled, (state, action) => {
				state.add_auction = true;
				auctionSlice.caseReducers.setAuctionList(state, action);
			})
			.addCase(getAuctionList.rejected, (state) => {
				state.auction_list_loading = false;
			})
			.addCase(joinAuction.pending, (state) => {
				state.join_auction_loading = true;
			})
			.addCase(joinAuction.fulfilled, (state, action) => {
				auctionSlice.caseReducers.setAllAuction(state, action);
				let upcoming_vehicles = Object.values(action.payload.upcoming_vehicles);
				let skipped_vehicles = Object.values(action.payload.skipped_vehicles);

				let payloadData = action.payload;

				let final_running_item = {};

				if (payloadData?.bid_detail?.amount) {
					final_running_item = {
						...action.payload,
						upcoming_vehicles,
						skipped_vehicles,
						auctionSound: true,
						event: 'NEW_BID',
					};
				} else {
					final_running_item = {
						...action.payload,
						upcoming_vehicles,
						skipped_vehicles,
						auctionSound: true,
					};
				}

				auctionSlice.caseReducers.setRunningAuction(state, final_running_item);
			})
			.addCase(joinAuction.rejected, (state) => {
				state.join_auction_loading = false;
				auctionSlice.caseReducers.clearAuction(state);
				// auctionSlice.caseReducers.finishAuction(state, action.payload?.auction_id);
			})
			.addCase(setWatchUnwatch.fulfilled, (state, action) => {
				auctionSlice.caseReducers.setWatch(state, action);
			})
			.addCase(setOfflineMax.fulfilled, (state, action) => {
				auctionSlice.caseReducers.setCurrentBidAmount(state, action);
			})
			.addCase(setOfflineMax.rejected, (state, action) => {
				auctionSlice.caseReducers.setCurrentBidAmount(state, action);
			});
	},
});

export const {
	setAddAuction,
	setRunningAuctionIds,
	clearAuction,
	removeRunningAuction,
	readyToBid,
	skippedVehicle,
	newBid,
	bonusTime,
	bidEnded,
	auctionBreak,
	setAuctionEnded,
	setAuctionSound,
	setMaxValue,
	setUpcomingVehicle,
	offlineMaxBidStatusChange,
} = auctionSlice.actions;

export default auctionSlice.reducer;
